import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        button: {
            textTransform: 'none',
            fontWeight: 'normal'
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#5368F0'
        },
        secondary: {
            main: '#FA9300',
            contrastText: '#FFF'
        }
    },
    overrides: {
        MuiFab: {
            root: {
                boxShadow: 'none'
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'none'
            },
            //textTransform: 'none'
        }
    },
    //shadows: ["none"]
});