import React from 'react';
import getUser from "../utils/getUser";

const ApplicationContext = React.createContext({
  fetch: () => {
    throw new Error('Fetch method not initialized.');
  },
  hello: () => {
    return 'hello'
  },
  user: () => {
    return getUser()
  }
});

export default ApplicationContext;
