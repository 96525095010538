import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

/**
 * Получить юзера на фронтенде
 * @param context
 * @returns {null|*}
 */
function getUser(context) {
    // Юзера из контекста можно передать для SSR (только не совсем понятно, поисковик не логинится)
    if (context && context.user) {
        return context.user;
    }

    let jwt = Cookies.get('id_token');

    if (jwt) {
        jwt = jwtDecode(jwt);
        return jwt;
    }

    return null;
}

export default getUser;

export function isAdmin(user) {
    return user && user.role && user.role === 'admin';
}