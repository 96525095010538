/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'themes' */ './home'),
    },
    {
      path: '/themes',
      load: () => import(/* webpackChunkName: 'themes' */ './themes'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/register',
      load: () => import(/* webpackChunkName: 'register' */ './register'),
    },
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/invite',
      load: () => import(/* webpackChunkName: 'invite' */ './invite'),
    },
    {
      // Только авторизованные. @see src/router.js
      path: '/invited',
      load: () => import(/* webpackChunkName: 'invited' */ './invited')
    },
    {
      path: '/join/:userId',
      load: () => import(/* webpackChunkName: 'join' */ './join'),
    },
    {
      path: '/joined',
      load: (res) => import(/* webpackChunkName: 'joined' */ './joined'),
    },
    {
      path: '/close',
      load: () => import(/* webpackChunkName: 'close' */ './close'),
    },
    {
      path: '/theme/:id',
      load: () => import(/* webpackChunkName: 'theme' */ './theme'),
    },
    {
      path: '/theme/edit/:id',
      load: () => import(/* webpackChunkName: 'create-theme' */ './theme/create'),
    },
    {
      path: '/themes/by/:userId',
      load: () => import(/* webpackChunkName: 'by-themes' */ './themes/by'),
    },
    {
      path: '/themes/my',
      load: () => import(/* webpackChunkName: 'my-themes' */ './themes/my'),
    },
    {
      path: '/themes/all',
      load: () => import(/* webpackChunkName: 'all-themes' */ './themes/all'),
    },
    {
      path: '/gallery/:guid',
      load: () => import(/* webpackChunkName: 'gallery' */ './gallery'),
    },
    {
      path: '/top/:query',
      load: () => import(/* webpackChunkName: 'top' */ './top'),
    },
    {
      path: '/top',
      load: () => import(/* webpackChunkName: 'top' */ './top'),
    },
    {
      path: '/pro',
      load: () => import(/* webpackChunkName: 'pro' */ './pro'),
    },
    {
      path: '/500',
      load: () => import(/* webpackChunkName: 'error500' */ './error500'),
    },
    {
      path: '/pro/dev-test',
      load: () => import(/* webpackChunkName: 'pro-dev' */ './pro/dev'),
    },
    {
      path: '/support',
      load: () => import(/* webpackChunkName: 'support' */ './support'),
    },
    {
      path: '/wallpaper/:id',
      load: () => import(/* webpackChunkName: 'wallpaper' */ './wallpaper'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - New Tab Theme Market`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
